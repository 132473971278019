import React from 'react';
import './ServicesSection.css';

const ServicesSection = () => {
  return (
    <div className="services-section">
      <h2 className="services-heading">Wide range of services?<br/> We got you covered</h2>
      <div className="marquee-container">
        <div className="marquee">
          <span className="service-item orange">Mobile App</span>
          <span className="service-item purple">Landing Pages</span>
          <span className="service-item blue">Websites</span>
          <span className="service-item teal">Web Applications</span>
          <span className="service-item pink">Logos</span>
          <span className="service-item orange">AI</span>
          <span className="service-item purple">Blockchain</span>
          <span className="service-item blue">Web3</span>
          <span className="service-item teal">MVP Prototype</span>
          <span className="service-item pink">Front-End Development</span>
          <span className="service-item orange">Back-end Development</span>
          <span className="service-item purple">App Deployment</span>
          <span className="service-item blue">Maintenance</span>

          {/* Repeat the items to make the loop seamless */}
          <span className="service-item orange">Mobile App</span>
          <span className="service-item purple">Landing Pages</span>
          <span className="service-item blue">Websites</span>
          <span className="service-item teal">Web Applications</span>
          <span className="service-item pink">Logos</span>
          <span className="service-item orange">AI</span>
          <span className="service-item purple">Blockchain</span>
          <span className="service-item blue">Web3</span>
          <span className="service-item teal">MVP Prototype</span>
          <span className="service-item pink">Front-End Development</span>
          <span className="service-item orange">Back-end Development</span>
          <span className="service-item purple">App Deployment</span>
          <span className="service-item blue">Maintenance</span>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
