import React from 'react';
import Hero from './components/Hero';
import LatestWork from './components/LatestWork';
import BenefitsSection from './components/BenefitsSection';
import ServicesSection from './components/ServicesSection';
import MembershipSection from './components/MembershipSection';
import FAQSection from './components/FAQSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import './App.css'; // Import the CSS file here

function App() {
    return (
        <div className="main-container">
            <Hero />
            <LatestWork />
            <BenefitsSection />
            <ServicesSection />
            <MembershipSection />
            <FAQSection />
            <ContactSection />
            <Footer /> 
        </div>
    );
}

export default App;
