import React from 'react';
import './ContactSection.css'; // Ensure this CSS file is linked properly

const ContactSection = () => {
    return (
        <section className="contact-section">
            <div className="contact-content">
                <h2 className="contact-heading">Grab a slot, book a call now.</h2>
                <p className="contact-subheading">
                    Get a personalized intro call from our owner who personally handles clients and partners, get a slot, learn more about the offer, or have a chit-chat.
                </p>
                <a href="https://calendly.com/mooneglobal/intro" target="_blank" rel="noopener noreferrer" className="cta-button">Book a call</a>
            </div>
        </section>
    );
};

export default ContactSection;
