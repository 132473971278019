import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import styles from './LatestWork.module.css';

// Import images
import image1 from '../assets/image-1.png';
import image2 from '../assets/image-2.png';
import image3 from '../assets/image-3.png';
import image4 from '../assets/image-4.png';
import image5 from '../assets/image-5.png';

// Individual image component
const ImageItem = ({ src, alt, style }) => (
    <animated.div className={styles.heroImageWrapper} style={style}>
        <img src={src} alt={alt} className={styles.imageFill} />
    </animated.div>
);

const LatestWork = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Spring for horizontal scroll animation
    const springProps = useSpring({
        x: -scrollPosition * 0.15,  // Apply 30% of the scroll position to the translation
    });

    return (
        <section id="latest-work" className={styles.latestWorkSection}>
            <div className={styles.latestProjectsWrapper}>
                <div className={styles.badgeText}>Latest Projects</div>
            </div>
            <animated.div
                className={styles.heroImagesWrapper}
                style={{ transform: springProps.x.to(x => `translateX(${x}px)`) }} // Apply spring animation
            >
                <ImageItem src={image1} alt="Project 1" />
                <ImageItem src={image2} alt="Project 2" />
                <ImageItem src={image3} alt="Project 3" />
                <ImageItem src={image4} alt="Project 4" />
                <ImageItem src={image5} alt="Project 5" />
            </animated.div>
        </section>
    );
};

export default LatestWork;
