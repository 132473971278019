import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import './Hero.css'; // Ensure the CSS file is properly linked
import logo from '../assets/moone-logo.svg';

const Hero = ({ title, subtitle, buttonText, buttonLink }) => {
    return (
        <section id="hero" className="hero">
            <div className="hero-content">
                <img src={logo} alt="Moone Logo" className="moone-logo" />
                <h1 className="hero-title">{title}</h1>
                <p className="hero-subtitle">{subtitle}</p>
                <Link to={buttonLink} className="cta-button">
                    {buttonText}
                </Link>
            </div>
        </section>
    );
};

Hero.defaultProps = {
    title: 'A development agency with a twist',
    subtitle: 'Design and development subscription, pause or cancel anytime.',
    buttonText: 'See plan',
    buttonLink: 'plans'
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
};

export default Hero;
