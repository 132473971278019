import React from 'react';
import './Footer.css'; // Make sure the path is correct based on your project structure
import mooneLogo from '../assets/moone-logo.svg'; // Adjust the path to your logo file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <img src={mooneLogo} alt="Moone Logo" className="footer-logo" />
                <p className="footer-text">Copyright Moone Global. All rights reserved 2024.</p>
            </div>
        </footer>
    );
}

export default Footer;
