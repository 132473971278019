import React, { useEffect, useRef } from 'react';
import './MembershipSection.css'; // Ensure this CSS file is linked properly

const MembershipSection = () => {
    const cardRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const card = cardRef.current;
            const rect = card.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top <= windowHeight - 100) {  // Adjust the threshold as needed
                card.classList.add('is-visible');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id= "plans" className="membership-section">
            <div className="membership-card" ref={cardRef}>
                <h2 className="membership-title">Membership</h2>
                <p className="membership-price">$3,990/m</p>
                <p className="membership-note">Pause or cancel anytime</p>
                <div className="membership-divider"></div>
                <p className="membership-features-title">2 slots left</p>
                <div className="membership-features">
                    <div>One request at a time</div>
                    <div>Full-stack development</div>
                    <div>2-4 days delivery</div>
                    <div>Unlimited revisions</div>
                    <div>Unlimited projects</div>
                    <div>Pause or cancel anytime</div>
                </div>
                <a href="https://calendly.com/mooneglobal/intro" target="_blank" rel="noopener noreferrer" className="cta-button">Get a slot</a>
            </div>
        </section>
    );
};

export default MembershipSection;
