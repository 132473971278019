import React from 'react';
import './BenefitsSection.css';
import featureIcon1 from '../assets/feature-icon-1.svg'; // Import the first SVG file
import featureIcon2 from '../assets/feature-icon-2.svg'; // Import the second SVG file
import featureIcon3 from '../assets/feature-icon-3.svg'; // Import the third SVG file
import featureIcon4 from '../assets/feature-icon-4.svg'; // Import the fourth SVG file
import featureIcon5 from '../assets/feature-icon-5.svg'; // Import the fifth SVG file
import featureIcon6 from '../assets/feature-icon-6.svg'; // Import the sixth SVG file
import featureIcon7 from '../assets/feature-icon-7.svg'; // Import the fourth SVG file
import featureIcon8 from '../assets/feature-icon-8.svg'; // Import the fifth SVG file
import featureIcon9 from '../assets/feature-icon-9.svg'; // Import the sixth SVG file

const BenefitsSection = () => {
    return (
        <div>
            {/* First Section */}
            <section className="benefits-section">
                <div className="benefits-content">
                    <h2 className="benefits-heading">Get the most work done, <br/> pay only a fixed cost.</h2>
                    <p className="benefits-subheading">Any project - We got you!</p>
                    <div className="benefits-features">
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon1} alt="Feature 1 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Request as many design and <br/> development tasks as you'd like.</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon2} alt="Feature 2 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Receive your request within two <br/> business days on average.</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon3} alt="Feature 3 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">We’ll continue to revise outputs  <br/> until you’re satisfied.</p>
                        </div>
                    </div>
                    <a href="#plans" className="cta-button">See plan</a>
                </div>
            </section>

            {/* Second Section */}
            <section className="benefits-section">
                <div className="benefits-content">
                    <h2 className="benefits-heading">We help startups design, develop, and launch products in weeks.</h2>
                    <p className="benefits-subheading">Moone replaces unreliable freelancers and expensive agencies for one flat monthly fee.</p>
                    <div className="benefits-features">
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon4} alt="Feature 4 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Easily manage your request <br/> queue with a Trello board.</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon5} alt="Feature 5 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Get your request done <br/> in just a few days, that’s fast!</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon6} alt="Feature 6 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Fixed monthly rate. <br/> Pay the same fixed price each month.</p>
                        </div>
                    </div>
                    <div className="benefits-features">
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon7} alt="Feature 7 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">We only hire top 3%  of <br/> designers and developers.</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon8} alt="Feature 8 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Scale up or down as needed,<br/> pause or cancel anytime.</p>
                        </div>
                        <div className="feature-item">
                            <div className="feature-icon">
                                <img src={featureIcon9} alt="Feature 9 Icon" width="160" height="160" />
                            </div>
                            <p className="feature-description">Each request is done exclusively <br/> for you and is yours 100%.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BenefitsSection;
