import React, { useState, useRef, useEffect } from 'react';
import './FAQSection.css';

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [heights, setHeights] = useState([]);

    const faqRefs = useRef([]);

    useEffect(() => {
        const newHeights = faqRefs.current.map(faqRef => faqRef.scrollHeight);
        setHeights(newHeights);
    }, []);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <h2 className="faq-title">Frequently asked questions</h2>
            <div className="faq-list">
                {[
                    "Why not just hire a full-time freelancer/employee?",
                    "How many requests can I have?",
                    "How fast will I receive my finished requests?",
                    "How does the pause feature work?",
                    "What happens when my request is too large?",
                    "What programs do you use?",
                    "Who owns the rights to the work that you've done?",
                    "What if I don't like the output or deliverables?",
                    "What if I don't need to use this service anymore?"
                ].map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div
                            className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => toggleFAQ(index)}
                        >
                            {faq}
                            <span className="faq-icon">
                                {activeIndex === index ? '›' : '›'}
                            </span>
                        </div>
                        <div
                            className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
                            ref={el => (faqRefs.current[index] = el)}
                            style={{ maxHeight: activeIndex === index ? `${heights[index]}px` : '0px' }}
                        >
                            <p>
                                {index === 0 && "Great question! Hiring a full-time freelancer can range from $20,000 to $40,000 per project, while the yearly cost of a senior-level employee can easily exceed $100,000, plus benefits. Additionally, you might not have enough work to keep them occupied full-time, meaning you’re paying for idle time."}
                                
                                {index === 1 && "You can add as many requests as you want in your task queue. They will be processed one after another."}

                                {index === 2 && "Most requests take 2-4 business days. Larger requests may take longer, while smaller requests may take less time."}

                                {index === 3 && "We always consider that you may not have enough work to fill up an entire month. That's where pausing your subscription comes in handy."}

                                {index === 4 && "We will break down your larger request. These are usually full-scale websites or mobile apps, UI/UX work, etc. You should expect to receive a reasonable amount of work every 48 hours until the entire request is done."}

                                {index === 5 && "Design requests are usually done in Figma and Adobe products. Apps are usually coded in Flutter or ReactJS, but development tools and frameworks are pretty flexible in terms of need."}

                                {index === 6 && "Every request that Moone fulfills under your subscription will be the client's ownership, it is 100% yours and yours only."}

                                {index === 7 && "It happens, but that's alright. We'll continue to revise your project until you are 100% satisfied."}

                                {index === 8 && "Not a problem at all. You can pause your subscription when finished and return when you have additional design or development needs. There's no wasted payment in our subscription offer."}
                            </p>
                            <p>
                                {index === 0 && "With our monthly plan, you have the flexibility to pause and resume your subscription as needed, ensuring that you only pay for design and development services when you actually have work available."}
                            </p>
                            <p>
                                 {index === 3 && "Your billing cycle is 31 days. If you pause on day 16, you will have 15 days of service remaining when you resume. Just let us know when you need a pause, it's that simple."}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default FAQSection;
